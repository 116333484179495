<template>
    <div class="content v-box" style="flex-wrap: wrap;" >
        <el-card :body-style="{ padding: '0px', margin: '8px',}"  v-for="item in cameraList" style="margin: 8px; height: fit-content;"
                 :key="item.id"
                 :label="item.name"
                 :value="item.id">
            <el-image @click="onPreview(item)"  :src="item.type == 'IOT-CAMERA' ? 'http://yuncangai.com/iotapi'+item.imageID : 'http://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID" style="height: 200px; width:auto; display: block; cursor: pointer" />
            <div style="padding: 14px;">
                <span>{{ myFormatDate(item.uploadTime) }}</span>
                <div class="bottom">
                    <!-- <time class="time">{{ myFormatDate(item.uploadTime) }}</time>-->
                    <el-button type="text" class="button" @click="onPreview(item)">查看大图</el-button>
                </div>
            </div>
        </el-card>
        <div class="pagination" style="width: 100%; margin-left: 8px; margin-right: 8px">
            <el-pagination
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[8, 12, 20, 40, 100]"
                    :current-page="pageNo"
                    :page-size="pageSize"
                    :total="count"
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
            ></el-pagination>
        </div>

        <el-image-viewer
                v-if="showViewer"
                @close="closeViewer"
                @switch="swith"
                :infinite="false"
                :initial-index="imageIndex"
                :url-list="viewerList" />
    </div>
</template>

<script>
    import {formatDate} from '../js/formatDate.js';

    export default {
        name: "CameraImageShare",
        mounted(){
            this.password = localStorage.getItem("camera_password_"+this.id)
            this.getList();
        },

        data(){
            return{
                id:this.$route.params.id,
                code:this.$route.params.code,
                password:null,
                cameraList:[],
                currentSrc:'',
                showViewer: false,  //大图
                viewerList:[],
                idList:[],
                imageIndex:0,
                pageNo: 1,
                pageSize: 8,
                count:0,
            }
        },
        watch: {
            $route(newV, oldV) {
                this.id = newV.params.id;
                this.getList();
            },

        },
        methods:{
            myFormatDate(time) {
                let date = new Date(time);
                return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
            },
            getList(){
                this.apiUtils.cameraImageListShareGet({sn:this.id, shareCode: this.code, sharePassword: this.password, pageSize:this.pageSize, pageNo:this.pageNo }).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.cameraList = res.data.list
                        this.count = res.data.count

                        let list = [];
                        this.idList=[];
                        for (let item of this.cameraList) {
                            list.push(item.type == 'IOT-CAMERA' ? 'http://yuncangai.com/iotapi'+item.imageID : 'http://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID)
                            this.idList.push(item.id);
                        }
                        this.viewerList = list;

                        //   this.pageSize = res.pageSize;
                        //  this.pageNo = res.pageNo
                    } else if(res.code == 39002){
                        this.$prompt('请输入', '访问码', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                        }).then(({ value }) => {
                            this.password = value;
                            localStorage.setItem("camera_password_"+this.id, this.password);
                            this.getList();
                        }).catch(() => {
                            this.getList();
                        });
                    } else{
                        this.$message.error("获取抓图列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            swith(index){
                console.error(index, this.idList)
                if (index == 0){
                    this.getPre(this.idList[index])
                } else if (index == (this.viewerList.length-1)){
                    this.getNext(this.idList[index])
                }
            },
            getPre(imgID){
                console.error(imgID)
                this.apiUtils.cameraImagePre({sn:this.id, id: imgID, size:10}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        let data = res.data
                        if (data){
                            let list = [];
                            let idS = [];
                            for (let item of data) {
                                list.push(item.type == 'IOT-CAMERA' ? 'http://yuncangai.com/iotapi'+item.imageID : 'http://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID)
                                idS.push(item.id)
                            }
                            for (let item of this.viewerList) {
                                list.push(item)
                            }
                            for (let item of this.idList) {
                                idS.push(item)
                            }
                            this.idList = idS;
                            this.viewerList = list;
                            this.imageIndex = data.length;
                        }

                    } else {

                    }

                });
            },
            getNext(imgID){
                console.error(imgID)
                this.apiUtils.cameraImageNext({sn:this.id, id: imgID, size:10}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        let data = res.data
                        if (data){
                            let list = [];
                            let idS = [];
                            for (let item of this.viewerList) {
                                list.push(item)
                            }
                            for (let item of this.idList) {
                                idS.push(item)
                            }
                            for (let item of data) {
                                list.push(item.type == 'IOT-CAMERA' ? 'http://yuncangai.com/iotapi'+item.imageID : 'http://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID)
                                idS.push(item.id)
                            }
                            this.idList = idS;
                            this.viewerList = list;
                        }
                    } else {

                    }

                });
            },
            // 初始页currentPage、初始每页数据数pagesize和数据data
            handleSizeChange: function (size) {
                this.pageSize = size;
                this.getList();
            },
            // 分页导航
            handlePageChange(val) {
                this.pageNo = val
                this.getList();
            },
            onPreview(item) {
                this.currentSrc = item.type == 'IOT-CAMERA' ? 'http://yuncangai.com/iotapi'+item.imageID : 'http://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID;
                this.imageIndex = this.viewerList.indexOf(this.currentSrc)
                this.showViewer = true
            },
            closeViewer() {
                console.error("..............................")
                this.showViewer = false

            },
        }
    }
</script>

<style scoped>
    /deep/ .is-disabled{
        visibility: hidden;
    }
    /deep/ .el-image__error, /deep/ .el-image__inner, /deep/ .el-image__placeholder{
        width: auto;
    }

    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button {
        padding: 0;
        min-height: auto;
    }
</style>